<!--
 * @Descripttion: 
 * @version: 
 * @Author: hutian
 * @Date: 2021-07-17 14:20:12
 * @LastEditors: hutian
 * @LastEditTime: 2021-07-17 14:40:30
-->
<template>
  <div class="application_record">
    <a-modal
      :maskClosable="false"
      style="top: 8px"
      :width="1000"
      :title="`【${title}】使用记录`"
      v-model="visible"
      :footer="null"
    >
      <a-table
        size="small"
        :data-source="tableData"
        bordered
        :rowKey="(record) => record.id"
        :pagination="page"
        @change="
          (p) => {
            page = p
            toSearch(2)
          }
        "
        :customRow="changeTableRow"
      >
        <a-table-column title="额度" data-index="specStr" align="num"> </a-table-column>
        <a-table-column title="支出类型" data-index="quotaSpendingStatus" align="center">
          <template slot-scope="text"> {{text | status}} </template>
        </a-table-column>
        <a-table-column title="配额类型" data-index="quotaType" align="center">
          <template slot-scope="text"> {{text | type}} </template>
        </a-table-column>
        <a-table-column title="备注" data-index="reason" align="center">
        </a-table-column>
      </a-table>
    </a-modal>
  </div>
</template>

<script>
export default {
  name: 'application_record',
  data() {
    return {
      visible: false,
      title: '门店',
      tableData: [],
      page: {
        current: 1,
        pageSize: 10,
        total: 0,
        size: 'small',
      },
    }
  },

  filters: {
    staus(num) {
      const typeMay = {
        1: '支出',
        2: '收入'
      }
      return typeMay[num]
    },
    type(num) {
      const typeMay = {
        1: '固定',
        2: '临时'
      }
      return typeMay[num]
    }
  },

  computed: {},

  created() {},

  methods: {
    // 获取数据
    getList(item) {
      this.title = item.storeName
      this.visible = true
      this.axios.get(`/api/base/quota/storeQuotaLog/list?storeId=${item.storeId}`).then((res) => {
        this.tableData = res.body.records
      })
    },

    changeTableRow(record) {
      return {
        on: {
          dblclick: (e) => {
            this.visible = false
            this.$emit('update:value', record.customerName)
            this.$emit('select', record)
            this.$emit('change')
          },
        },
      }
    },
  },
}
</script>

<style lang='scss' scoped>
</style>